import React from "react"
import styles from './PageLayout.module.scss'
import { Header } from "../../components/Header"
import { Breadcrumbs } from "../../components/Breadcrumbs";
import {Footer} from "../../components/Footer";

type IPageLayout = {
    children: React.ReactNode
}

const PageLayout = (props: IPageLayout) => {
    const { children } = props
    return (
        <>
            <div className={styles.page}>
                <Header />
                <Breadcrumbs />
                {children}
            </div>
            <Footer/>
        </>

    )
}

export default PageLayout