import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from './AccountPage.module.scss';
import {profileActions} from "../../bus/profile/actions";
import {getProfile} from "../../bus/profile/selectors";
import {Search} from "../../components/Search";
import {ShopProduct} from "../../models/shopTypes";
import {Product} from "../../components/Product";
import Pagination from "@mui/material/Pagination";
import {authenticationActions} from "../../bus/authentication/actions";

const AccountPage = () => {
    const dispatch = useDispatch()

    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        dispatch(profileActions.getProfileFavouritesRequest({ 'PageNumber': 1}))
    }, [])

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
        dispatch(profileActions.getProfileFavouritesRequest({ 'PageNumber': value }))
    }

    const handleLogout = () => {
        dispatch(authenticationActions.logoutAsync())
    }

    const profile = useSelector(getProfile)
    return (
        <div className={styles.account}>
            <div className={styles.account__info}>
                <div>
                    <h1>{profile.username}</h1>
                    <h2>Избранные товары</h2>
                </div>
                <button onClick={handleLogout}>Выйти</button>
            </div>

            <Search setValue={setSearch} />
            <div className={styles.account__favourites}>
                {profile.favourites.products
                    .filter(item => new RegExp(search, 'i').test(item.title))
                    .map((x: ShopProduct, index: number) => {
                        return <Product
                            key={index}
                            title={x.title}
                            img={x.img}
                            isInInventory={x.isInInventory}
                            isOnRequest={x.isOnRequest}
                            price={x.price}
                            modelId={x.modelId}
                            brandId={x.brandId}
                            countryId={x.countryId}
                            actions={x.actions}
                            isFavourite={x.isFavourite}
                            id={x.id}
                        />
                    })}
            </div>
            <Pagination
                count={profile.favourites.pageCount}
                page={page}
                sx={{
                    "& .MuiPagination-ul": { gap: '8px', justifyContent: 'center' },
                    "& .MuiPaginationItem-root": { width: '39px', height: '39px',  fontFamily: 'Barlow, sans-serif', fontSize: '18px', fontWeight: '600',
                        borderRadius: '0', backgroundColor: '#FFFFFF'},
                    "& .Mui-selected": {border: '2px solid #1C62CD', color: '#1C62CD'}
                }}
                onChange={handleChangePage}
                hideNextButton
                hidePrevButton
            />
        </div>
    )
}

export default AccountPage