import { takeLatest } from "redux-saga/effects";
import {profileTypes} from "./types";
import {getProfileFavouritesWorker, getProfileWorker} from "./workers";

export function* getProfileWatcher() {
    yield takeLatest(profileTypes.GET_PROFILE_ASYNC, getProfileWorker)
}

export function* getProfileFavouritesWatcher() {
    yield takeLatest(profileTypes.GET_PROFILE_FAVOURITES_REQUEST, getProfileFavouritesWorker)
}