import { AxiosResponse } from 'axios'
import qs from 'qs';
import {GetProductFilters, ShopProductsList} from "../models/shopTypes";
import baseApi from "./baseApi";

export const shopApi = {
    getFilters: () => {
        return baseApi.get('/api/products/getFilter')
    },

    getProducts: (payload: GetProductFilters | Object): Promise<AxiosResponse<ShopProductsList>> => {
        return baseApi.get('/api/products', {
            params: payload, paramsSerializer: {
                serialize: (params) => {
                    return qs.stringify(params)
                },
            }
        })
    },

    setFavourite: (payload: string) => {
        return baseApi.put(`/api/products/${payload}/setFavourite`)
    },

    removeFavourite: (payload: string) => {
        return baseApi.put(`/api/products/${payload}/removeFavourite`)
    },


}