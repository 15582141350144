import styles from './Footer.module.scss'
import { ReactComponent as Inst } from '../../assets/images/icons/inst.svg'
import { ReactComponent as VK } from '../../assets/images/icons/vk.svg'
import { ReactComponent as Facebook } from '../../assets/images/icons/facebook.svg'
import { ReactComponent as Youtube } from '../../assets/images/icons/youtube.svg'
import {FilterWrapper} from "../FilterWrapper";

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer__wrapper}>
                <div className={styles.mailing}>
                    <h4 className={styles.mailing__title}>Подпишитесь на нашу рассылку
                        и узнавайте об акциях быстрее</h4>
                    <div className={styles.mailing__form}>
                        <input
                            className={styles.mailing__input}
                            type="email"
                            placeholder="Введите ваш e-mail:"
                        />
                        <button className={styles.mailing__submit}>Отправить</button>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.col__desktop}>
                        <h4 className={styles.col__title}>Информация</h4>
                        <div className={styles.col__list}>
                            <a className={styles.col__item} href="#">О компании</a>
                            <a className={styles.col__item} href="#">Контакты</a>
                            <a className={styles.col__item} href="#">Акции</a>
                            <a className={styles.col__item} href="#">Магазины</a>
                        </div>
                    </div>
                    <div className={styles.col__mobile}>
                        <FilterWrapper title={'Информация'} type={'mobile'}>
                            <div className={styles.col__list}>
                                <a className={styles.col__item} href="#">О компании</a>
                                <a className={styles.col__item} href="#">Контакты</a>
                                <a className={styles.col__item} href="#">Акции</a>
                                <a className={styles.col__item} href="#">Магазины</a>
                            </div>
                        </FilterWrapper>
                    </div>
                </div>
                <div className={styles.col}>
                    <div className={styles.col__desktop}>
                        <h4 className={styles.col__title}>Интернет-магазин</h4>
                        <div className={styles.col__list}>
                            <a className={styles.col__item} href="#">Доставка и самовывоз</a>
                            <a className={styles.col__item} href="#">Оплата</a>
                            <a className={styles.col__item} href="#">Возврат-обмен</a>
                            <a className={styles.col__item} href="#">Новости</a>
                        </div>
                    </div>
                    <div className={styles.col__mobile}>
                        <FilterWrapper title={'Интернет-магазин'} type={'mobile'}>
                            <div className={styles.col__list}>
                                <a className={styles.col__item} href="#">Доставка и самовывоз</a>
                                <a className={styles.col__item} href="#">Оплата</a>
                                <a className={styles.col__item} href="#">Возврат-обмен</a>
                                <a className={styles.col__item} href="#">Новости</a>
                            </div>
                        </FilterWrapper>
                    </div>
                </div>
                <address className={styles.social}>
                    <div className={styles.social__list}>
                        <a className={styles.social__item} href="#"><Inst/></a>
                        <a className={styles.social__item} href="#"><VK/></a>
                        <a className={styles.social__item} href="#"><Facebook/></a>
                        <a className={styles.social__item} href="#"><Youtube/></a>
                    </div>
                </address>
            </div>
        </footer>
    )
}

export default Footer