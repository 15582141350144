import {LoginData, RegisterData} from "../models/authenticationTypes";
import baseApi from "./baseApi";

export const authenticationApi = {
    login: (data: LoginData) => {
        return baseApi.post('/login', data)
    },

    registration: (data: RegisterData) => {
        return baseApi.post('/registration', data)
    },

    refresh: () => {
        return baseApi.get('/refresh')
    },

    logout: () => {
        return baseApi.post('/logout')
    }
}