import { createAction } from '@reduxjs/toolkit'
import {shopTypes} from "./types";

export const shopActions = {
    getFiltersRequest: createAction(shopTypes.GET_FILTERS_REQUEST),
    getFiltersSuccess: createAction(shopTypes.GET_FILTERS_SUCCESS, (data) => ({
        payload: data,
    })),
    getFiltersFailure: createAction(shopTypes.GET_FILTERS_FAILURE, (error: string) => ({
        payload: error,
    })),

    getProductsRequest: createAction(shopTypes.GET_PRODUCTS_REQUEST, (data) => ({
        payload: data,
    })),
    getProductsSuccess: createAction(shopTypes.GET_PRODUCTS_SUCCESS, (data) => ({
        payload: data,
    })),
    getProductsFailure: createAction(shopTypes.GET_PRODUCTS_FAILURE, (error: string) => ({
        payload: error,
    })),

    setFavouriteProductRequest: createAction(shopTypes.SET_FAVOURITE_PRODUCT_REQUEST, (data) => ({
        payload: data
    })),
    setFavouriteProductSuccess: createAction(shopTypes.SET_FAVOURITE_PRODUCT_SUCCESS, (data) => ({
        payload: data
    })),
    setFavouriteProductFailure: createAction(shopTypes.SET_FAVOURITE_PRODUCT_FAILURE, (error: string) => ({
        payload: error
    })),
    removeFavouriteProductRequest: createAction(shopTypes.REMOVE_FAVOURITE_PRODUCT_REQUEST, (data) => ({
        payload: data
    })),
    removeFavouriteProductSuccess: createAction(shopTypes.REMOVE_FAVOURITE_PRODUCT_SUCCESS, (data) => ({
        payload: data
    })),
    removeFavouriteProductFailure: createAction(shopTypes.REMOVE_FAVOURITE_PRODUCT_FAILURE, (error: string) => ({
        payload: error
    })),

    clearState: createAction(shopTypes.CLEAR_STATE),
}
