import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

type IShopCheckbox = {
    id?: string
    label: string
    onChangeFunc: ()=>void
}

const ShopCheckbox = (props: IShopCheckbox) => {
    const { id, label, onChangeFunc } = props
    return (
        <div>
            <FormControlLabel control={
                <Checkbox sx={{
                    '&.MuiCheckbox-root': { color: "#C4C4C4"},
                    '&.Mui-checked': { color: "#1C62CD" },
                    '& .MuiSvgIcon-root': { fontSize: 14 },
                }}
                          onChange={onChangeFunc}
                />
            }
                              label={label}
                              id={id}
                              sx={{
                                  '& .MuiTypography-root': { fontSize: 14, fontFamily: "SF Pro Display", fontWeight: '400', color: '#2F3035'}
                              }}/>
        </div>
    )
}

export default ShopCheckbox