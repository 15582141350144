import { ShopPage } from "../pages/ShopPage";
import { AccountPage } from "../pages/AccountPage";
import { Navigate } from "react-router-dom";

type IBook = {
    id: number
    title: string
    path: string
    element: JSX.Element
}

const nonAuthRoutesBook: IBook[] = [
    {
        id: 1,
        title: 'Магазин',
        path: '/shop',
        element: <ShopPage/>,
    },
    {
        id: 2,
        title: 'Магазин',
        path: '*',
        element: <Navigate to={'/shop'} />
    }
]

const authRoutesBook: IBook[] = [
    {
        id: 3,
        title: 'Магазин',
        path: '/shop',
        element: <ShopPage/>,
    },
    {
        id: 4,
        title: 'Личный кабинет',
        path: '/account',
        element: <AccountPage/>
    },
    {
        id: 5,
        title: 'Магазин',
        path: '*',
        element: <Navigate to={'/shop'} />
    }
]

export { nonAuthRoutesBook, authRoutesBook }