export const authenticationTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',
    LOGIN_ASYNC: 'LOGIN_ASYNC',

    REGISTRATION_REQUEST: 'REGISTRATION_REQUEST',
    REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
    REGISTRATION_FAILED: 'REGISTRATION_FAILED',
    REGISTRATION_ASYNC: 'REGISTRATION_ASYNC',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILED: 'LOGOUT_FAILED',
    LOGOUT_ASYNC: 'LOGOUT_ASYNC',

    REFRESH_REQUEST: 'REFRESH_REQUEST',
    REFRESH_SUCCESS: 'REFRESH_SUCCESS',
    REFRESH_FAILED: 'REFRESH_FAILED',
    REFRESH_ASYNC: 'REFRESH_ASYNC',
}
