import { createAction } from '@reduxjs/toolkit'

import { profileTypes } from './types'

export const profileActions = {
    getProfileRequest: createAction(profileTypes.GET_PROFILE_REQUEST),
    getProfileSuccess: createAction(profileTypes.GET_PROFILE_SUCCESS, data => ({ payload: data })),
    getProfileFailed: createAction(profileTypes.GET_PROFILE_FAILED, data => ({ payload: data })),
    getProfileAsync: createAction(profileTypes.GET_PROFILE_ASYNC),

    getProfileFavouritesRequest: createAction(profileTypes.GET_PROFILE_FAVOURITES_REQUEST, (data) => ({
        payload: data,
    })),
    getProfileFavouritesSuccess: createAction(profileTypes.GET_PROFILE_FAVOURITES_SUCCESS, (data) => ({
        payload: data,
    })),
    getProfileFavouriteFailure: createAction(profileTypes.GET_PROFILE_FAVOURITES_FAILURE, (error: string) => ({
        payload: error,
    })),
}
