import { all, call } from 'redux-saga/effects'
import {
    getFiltersWatcher,
    getProductsWatcher,
    removeFavouriteProductWatcher,
    setFavouriteProductWatcher
} from "../../bus/shop/watchers";
import {loginWatcher, logoutWatcher, refreshWatcher, registerWatcher} from "../../bus/authentication/watchers";
import {getProfileFavouritesWatcher, getProfileWatcher} from "../../bus/profile/watchers";

export function* rootSaga() {
    yield all([
        call(getFiltersWatcher),
        call(getProductsWatcher),
        call(loginWatcher),
        call(registerWatcher),
        call(refreshWatcher),
        call(getProfileWatcher),
        call(setFavouriteProductWatcher),
        call(removeFavouriteProductWatcher),
        call(getProfileFavouritesWatcher),
        call(logoutWatcher)
    ])
}