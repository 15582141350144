import React, { useEffect, useReducer, useState } from "react";
import styles from './ShopFilters.module.scss'
import {Checkbox, Tab, Tabs} from "@mui/material";
import List from '@mui/material/List';
import {FilterWrapper} from "../FilterWrapper";
import FormGroup from '@mui/material/FormGroup';
import {ShopCheckbox} from "../../ui/ShopCheckbox";
import Slider from '@mui/material/Slider';
import {useDispatch, useSelector } from 'react-redux'
import {getFilters} from "../../bus/shop/selectors";
import {Promo} from "../../ui/Promo";
import {GetProductFilters, ShopFiltersModels} from "../../models/shopTypes";
import {shopActions} from "../../bus/shop/actions";


const ShopFilters = () => {
    const { maxPrice, minPrice, countries, brands, models } = useSelector(getFilters)
    const reduxDispatch = useDispatch();
    const initialState: GetProductFilters = {
        'Actions': [],
        'Brands': [],
        'Countries': [],
        'IsInInventory': false,
        'IsOnRequest': false,
        'MaxPrice': 0,
        'MinPrice': 0,
        'Models': [],
        'PageNumber': 0,
        'PageSize': 0
    }

    enum ActionKind {
        SetIsInInventory = 'SET_IS_IN_INVENTORY',
        SetIsOnRequest = 'SET_IS_ON_REQUEST',
        SetMinPrice = 'SET_MIN_PRICE',
        SetMaxPrice = 'SET_MAX_PRICE',
        SetBrands = 'SET_BRANDS',
        SetModels = 'SET_MODELS',
        SetCountries = 'SET_COUNTRIES',
        SetActions = 'SET_ACTIONS',
        ClearState = 'CLEAR_STATE'
    }

    type Action = {
        type: ActionKind,
        payload?: number | string
    }

    const arrayAction = (array: string[], value: string) => {
        array.includes(value) ? array.splice(array.indexOf(value), 1) : array.push(value)
        return array
    }

    const reducer = (state: GetProductFilters, action: Action) : GetProductFilters => {
        const {type, payload} = action;
        switch (type) {
            case ActionKind.SetIsInInventory:
                return {
                    ...state,
                    IsInInventory: !state.IsInInventory
                }
            case ActionKind.SetIsOnRequest:
                return {
                    ...state,
                    IsOnRequest: !state.IsOnRequest
                }
            case ActionKind.SetMinPrice:
                return {
                    ...state,
                    MinPrice: Number(payload)
                }
            case ActionKind.SetMaxPrice:
                return {
                    ...state,
                    MaxPrice: Number(payload)
                }
            case ActionKind.SetBrands:
                return {
                    ...state,
                    Brands: arrayAction(state.Brands, payload as string)
                }
            case ActionKind.SetModels:
                return {
                    ...state,
                    Models: arrayAction(state.Models, payload as string)
                }
            case ActionKind.SetCountries:
                return {
                    ...state,
                    Countries: arrayAction(state.Countries, payload as string)
                }
            case ActionKind.SetActions:
                let actionsArr = state.Actions
                actionsArr.includes(payload as number)
                    ? actionsArr.splice(actionsArr.indexOf(payload as number), 1)
                    : actionsArr.push(payload as number)
                return {
                    ...state,
                    Actions: actionsArr
                }
            case ActionKind.ClearState:
                return initialState
            default:
                return state
        }
    }
    const [state, dispatch] = useReducer(reducer, initialState);

    const [tabValue, setTabValue] = useState(0);
    const [sliderValue, setSliderValue] = useState<number[]>([0,0]);

    useEffect(()=> {
        setSliderValue([minPrice, maxPrice])
    },[minPrice, maxPrice])

    useEffect(() => {
        dispatch({type: ActionKind.SetMinPrice, payload: sliderValue[0]})
        dispatch({type: ActionKind.SetMaxPrice, payload: sliderValue[1]})
    },[sliderValue])

    const handleTabsChange = (event: React.ChangeEvent<{}>, newTabsValue: number) => {
        setTabValue(newTabsValue);
    }

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setSliderValue(newValue as number[]);
    };

    const submitFilters = () => {
        const asArray = Object.entries(state);
        let filtered = asArray.map(([key, value]) => {
            if((typeof value === 'object' && value.length !== 0) ||
                (typeof value === 'boolean' && value) ||
                (typeof value === 'number' && value !== 0)) {
                return [key, value]
            }
            return;
        });
        filtered = filtered.filter((x) => x !== undefined)
        let filterToSend = {}
        // @ts-ignore
        filtered.map(([key, value]) => {
            // @ts-ignore
            filterToSend[key] = value
        })
        reduxDispatch(shopActions.getProductsRequest(filterToSend))
    }
    return (
        <div className={styles.filters}>
            <Tabs
                value={tabValue}
                onChange={handleTabsChange}
                sx={{
                    "& .MuiTabs-flexContainer": { gap: '16px' },
                    "&.MuiTabs-root": { minHeight: 'fit-content' },
                    "& .MuiButtonBase-root": { minHeight: 'fit-content' },
                    "& button": { color: '#C4C4C4', fontFamily: "SF Pro Display, sans-serif", fontWeight: '400',
                        fontSize: '16px', paddingTop: '8px', paddingLeft: '0', paddingRight: '0', paddingBottom: '8px',
                        textTransform: 'uppercase', letterSpacing: '0.09em'},
                    "& button.Mui-selected": { color: '#2F3035', fontWeight: '700'},
                }}
            >
                <Tab label={'параметры'} />
                <Tab label={'по марке'} />
            </Tabs>
            <List>
                <FilterWrapper title={'Наличие'} type={'desktop'}>
                    <FormGroup sx={{
                        '&.MuiFormGroup-root': { flexDirection: 'row'}
                    }}>
                        <ShopCheckbox label={'В наличие'} onChangeFunc={() => dispatch({ type: ActionKind.SetIsInInventory})} />
                        <ShopCheckbox label={'Под заказ'} onChangeFunc={() => dispatch({ type: ActionKind.SetIsOnRequest})} />
                    </FormGroup>
                </FilterWrapper>
                <FilterWrapper title={'Цена'} type={'desktop'}>
                    <div className={styles.slider__wp}>
                        <Slider
                            value={sliderValue}
                            onChange={handleSliderChange}
                            min={minPrice}
                            max={maxPrice}
                            sx={{
                                "&.MuiSlider-root": { marginLeft: '4px',width: '95%'},
                                "& .MuiSlider-thumb": { width: '8px', height: '8px' },
                                "& .MuiSlider-track": { height: '1px'},
                                "& .MuiSlider-rail": {color: '#E0E0E0'},
                            }}
                            disableSwap
                        />
                        <div className={styles.filters__slider}>
                            <div className={styles.slider__input_cont}>
                                <span>от</span>
                                <div className={styles.slider__input}
                                     style={{width: String(sliderValue[0]).length+'ch'}}>{sliderValue[0]}</div>
                            </div>
                            <div className={styles.slider__input_cont}>
                                <span>до</span>
                                <div className={styles.slider__input}
                                     style={{width: String(sliderValue[1]).length+'ch'}}>{sliderValue[1]}</div>
                            </div>
                        </div>
                    </div>
                </FilterWrapper>
                <FilterWrapper title={'Бренд'} type={'desktop'}>
                    <FormGroup sx={{
                        '&.MuiFormGroup-root': { flexDirection: 'row'}
                    }}>
                        {brands.map((brand: ShopFiltersModels, index: number) => {
                            return <ShopCheckbox label={brand.title} id={brand.id} key={index}
                                                 onChangeFunc={() => dispatch({ type: ActionKind.SetBrands, payload: brand.id})}/>
                        })}
                    </FormGroup>
                </FilterWrapper>
                <FilterWrapper title={'Модель'} type={'desktop'}>
                    <FormGroup sx={{
                        '&.MuiFormGroup-root': { flexDirection: 'row'}
                    }}>
                        {models.map((model: ShopFiltersModels, index: number) => {
                            return <ShopCheckbox label={model.title} id={model.id} key={index}
                                                 onChangeFunc={() => dispatch({ type: ActionKind.SetModels, payload: model.id})}/>
                        })}
                    </FormGroup>
                </FilterWrapper>
                <FilterWrapper title={'Акция'} type={'desktop'}>
                    <FormGroup sx={{
                        '&.MuiFormGroup-root': { flexDirection: 'row', gap: '10px'}
                    }}>
                        {['SALE', 'NEW', 'HIT', 'ДИЛЕР'].map((item, index) => {
                            return <Checkbox icon={<Promo name={item} inactive={true}/>} key={index}
                                             sx={{"&.MuiCheckbox-root": {padding: 0}}}
                                             checkedIcon={<Promo name={item} inactive={false}/>}
                                             onChange={()=>dispatch({ type: ActionKind.SetActions, payload: index})}
                            />
                        })}
                    </FormGroup>
                </FilterWrapper>
                <FilterWrapper title={'Страны'} type={'desktop'}>
                    <FormGroup sx={{
                        '&.MuiFormGroup-root': { flexDirection: 'row'}
                    }}>
                        {countries.map((country: ShopFiltersModels, index: number) => {
                            return <ShopCheckbox label={country.title} id={country.title} key={index}
                                                 onChangeFunc={() => dispatch({ type: ActionKind.SetCountries, payload: country.id})}/>
                        })}
                    </FormGroup>
                </FilterWrapper>
            </List>
            <button className={styles.filters__button} onClick={submitFilters}>Выбрать</button>
            <div className={styles.filters__reset} onClick={() => {dispatch({ type: ActionKind.ClearState }); reduxDispatch(shopActions.getProductsRequest({ 'PageNumber': 1}))}}>Сбросить фильтр</div>
        </div>
    )
}

export default ShopFilters