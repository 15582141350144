import { takeLatest } from "redux-saga/effects";
import {authenticationTypes} from "./types";
import {loginWorker, logoutWorker, refreshWorker, registrationWorker} from "./workers";

export function* loginWatcher() {
    yield takeLatest(authenticationTypes.LOGIN_ASYNC, loginWorker)
}

export function* registerWatcher() {
    yield takeLatest(authenticationTypes.REGISTRATION_ASYNC, registrationWorker)
}

export function* refreshWatcher() {
    yield takeLatest(authenticationTypes.REFRESH_ASYNC, refreshWorker)
}

export function* logoutWatcher() {
    yield takeLatest(authenticationTypes.LOGOUT_ASYNC, logoutWorker)
}