import {call, put } from "redux-saga/effects";
import {authenticationActions} from "./actions";
import {authenticationApi} from "../../api/authenticationApi";
import {LoginData, LoginRequest, RegisterData} from "../../models/authenticationTypes";
import { AxiosResponse } from "axios";

type actionType = {
    type: string
    payload: LoginData | RegisterData | any
}

export function* loginWorker(payload: actionType) {
    yield put(authenticationActions.loginRequest())
    const response: AxiosResponse<LoginRequest> = yield call(authenticationApi.login, payload.payload)

    if (response && Object.prototype.toString.call(response) !== '[object String]') {
        localStorage.setItem('@ACCESS_TOKEN', response.data.token)
        yield put(authenticationActions.loginSuccess(true))
    } else {
        yield put(authenticationActions.loginFailed(response))
    }
}

export function* registrationWorker(payload : actionType) {
    yield put(authenticationActions.registrationRequest())
    const response: AxiosResponse = yield call(authenticationApi.registration, payload.payload)
    // @ts-ignore
    if (response && Object.prototype.toString.call(response) !== '[object String]' && !response.errorMessage) {
        yield put(authenticationActions.registrationSuccess(true))
    } else {
        if(Object.prototype.toString.call(response) === '[object String]') yield put(authenticationActions.registrationFailed(response))
        else { // @ts-ignore
            yield put(authenticationActions.registrationFailed(response.errorMessage))
        }
    }
}

export function* refreshWorker(): any {
    yield put(authenticationActions.checkRequest())
    const token = localStorage.getItem('@ACCESS_TOKEN')
    if (token) {
        const response = yield call(authenticationApi.refresh)
        if (response) {
            localStorage.setItem('@ACCESS_TOKEN', response.data.token)
            yield put(authenticationActions.checkSuccess(true))
        } else {
            yield put(authenticationActions.checkFailed(null))
        }
    } else {
        yield put(authenticationActions.checkSuccess(false))
    }
}

export function* logoutWorker(): any {
    yield put(authenticationActions.logoutRequest())

    const response = yield call(authenticationApi.logout)

    if (response) {
        localStorage.removeItem('@ACCESS_TOKEN')
        yield put(authenticationActions.logoutSuccess())
    } else {
        yield put(authenticationActions.logoutFailed(null))
    }
}
