import { PageLayout } from "../layouts/PageLayout";
import { Route, Routes } from "react-router-dom";
import { authRoutesBook, nonAuthRoutesBook } from "./book";

const setRoutes = (isAuth: boolean) => {
    if(isAuth || !!localStorage.getItem('@ACCESS_TOKEN')) {
        return (
            <PageLayout>
                <Routes>
                    {authRoutesBook.map((item) => {
                        return <Route path={item.path} element={item.element} key={item.id} />
                    })}
                </Routes>
            </PageLayout>
        )
    }
    return (
        <PageLayout>
            <Routes>
                {nonAuthRoutesBook.map((item) => {
                    return <Route path={item.path} element={item.element} key={item.id} />
                })}
            </Routes>
        </PageLayout>
    )
}

export default setRoutes