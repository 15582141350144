import {call, put } from "redux-saga/effects"
import {profileActions} from "./actions";
import profileApi from "../../api/profileApi";
import {FavouriteProductsList, FavouriteProductsRequestPayload} from "../../models/profileTypes";
import { AxiosResponse } from "axios";

export function* getProfileWorker(): any {
    yield put(profileActions.getProfileRequest())
    const response = yield call(profileApi.getProfile)

    if (response) {
        yield put(profileActions.getProfileSuccess(response.data))
    } else {
        yield put(profileActions.getProfileFailed(response.data.error))
    }
}

export function* getProfileFavouritesWorker(payload: FavouriteProductsRequestPayload) {
    // @ts-ignore
    const response: AxiosResponse<FavouriteProductsList> = yield call(profileApi.getFavourites, payload.payload)

    if(response) {
        const data = response.data
        const result: FavouriteProductsList = {
            pageCount: data.pageCount,
            products: data.products
        }
        yield put(profileActions.getProfileFavouritesSuccess(result))
    } else {
        yield put(profileActions.getProfileFavouriteFailure('Нет данных'))
    }
}