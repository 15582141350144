import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './routes';
import { Provider } from 'react-redux'
import './index.scss';
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
      <BrowserRouter>
          <Provider store={store}>
              <App />
          </Provider>
      </BrowserRouter>
  // </React.StrictMode>
);