import { call, put } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { shopApi } from "../../api/shopApi";
import {
    ShopFiltersCountriesResponse,
    ShopFiltersInfo,
    ShopFiltersModels, ShopProductsList, ShopProductsListRequest, ShopProductsRequestPayload
} from "../../models/shopTypes";
import { shopActions} from "./actions";

export function* getFiltersWorker() {
    const response: AxiosResponse<ShopFiltersCountriesResponse> = yield call(shopApi.getFilters)
    const brands: ShopFiltersModels[] = []
    const models: ShopFiltersModels[] = []
    const countries: ShopFiltersModels[] = []
    if (response) {
        response.data.countries.forEach((c) => {
            countries.push({id: c.id, title: c.title})
            c.brands.forEach((b) => {
                brands.push({id: b.id, title: b.title})
                b.models.forEach((m) => models.push({id: m.id, title: m.title}))
            })
        })

        let result: ShopFiltersInfo = {
            brands: brands,
            countries: countries,
            models: models,
            minPrice: response.data.minPrice,
            maxPrice: response.data.maxPrice,
        } ?? {}
        yield put(shopActions.getFiltersSuccess(result))
    } else {
        yield put(shopActions.getFiltersFailure('Нет данных'))
    }
}

export function* getProductsWorker(payload : ShopProductsRequestPayload) {
    const response: AxiosResponse<ShopProductsListRequest> = yield call(shopApi.getProducts, payload.payload)
    if(response) {
        const data = response.data
        const result: ShopProductsList = {
            pageSize: data.pageCount,
            products: data.products
        }
        yield put(shopActions.getProductsSuccess(result))
    }
    else {
        yield put(shopActions.getProductsFailure('Нет данных'))
    }
}

export function* setFavouriteProductWorker(payload: { type: string, payload: string }) {
    let response: AxiosResponse = yield call(shopApi.setFavourite, payload.payload)
    if(response) {
        yield put(shopActions.setFavouriteProductSuccess(true))
    }
    else {
        yield put(shopActions.getProductsFailure('Ошибка'))
    }
}

export function* removeFavouriteProductWorker(payload: { type: string, payload: string }) {
    let response: AxiosResponse = yield call(shopApi.removeFavourite, payload.payload)
    if(response) {
        yield put(shopActions.removeFavouriteProductSuccess(true))
    }
    else {
        yield put(shopActions.removeFavouriteProductFailure('Ошибка'))
    }
}