import { createReducer } from '@reduxjs/toolkit'
import {    shopActions} from "./actions";
import {ShopProductsList} from "../../models/shopTypes";

type FiltersState = {
    minPrice: number
    maxPrice: number
    countries: []
    models: []
    brands: []
    shopInfo: ShopProductsList
}


const initialState: FiltersState = {
    minPrice: 0,
    maxPrice: 0,
    countries: [],
    models: [],
    brands: [],
    shopInfo: { pageSize: 0, products: [] }
}

export const shopReducer = createReducer(initialState, builder => {
    builder.addCase(shopActions.getFiltersRequest, state => ({
        ...state,
    }))
    builder.addCase(shopActions.getFiltersSuccess, (state, action) => ({
        ...state,
        minPrice: action.payload.minPrice,
        maxPrice: action.payload.maxPrice,
        brands: action.payload.brands,
        models: action.payload.models,
        countries: action.payload.countries,
        error: null,
    }))
    builder.addCase(shopActions.getFiltersFailure, (state, action) => ({
        ...state,
        error: action.payload,
    }))

    builder.addCase(shopActions.getProductsRequest, state => ({
        ...state,
        isLoadingGet: true,
    }))
    builder.addCase(shopActions.getProductsSuccess, (state, action) => ({
        ...state,
        isLoadingGet: false,
        shopInfo: action.payload,
        error: null,
    }))
    builder.addCase(shopActions.getProductsFailure, (state, action) => ({
        ...state,
        isLoadingGet: false,
        error: action.payload,
    }))

    builder.addCase(shopActions.setFavouriteProductRequest, state => ({
        ...state,
    }))

    builder.addCase(shopActions.setFavouriteProductSuccess, state => ({
        ...state,
    }))

    builder.addCase(shopActions.setFavouriteProductFailure, (state, action) => ({
        ...state,
        error: action.payload,
    }))

    builder.addCase(shopActions.removeFavouriteProductRequest, state => ({
        ...state,
    }))

    builder.addCase(shopActions.removeFavouriteProductSuccess, state => ({
        ...state,
    }))

    builder.addCase(shopActions.removeFavouriteProductFailure, (state, action) => ({
        ...state,
        error: action.payload,
    }))
})
