import { takeLatest } from 'redux-saga/effects'
import {shopTypes} from "./types";
import {getFiltersWorker, getProductsWorker, removeFavouriteProductWorker, setFavouriteProductWorker} from "./workers";

export function* getFiltersWatcher() {
    yield takeLatest(shopTypes.GET_FILTERS_REQUEST, getFiltersWorker)
}

export function* getProductsWatcher() {
    yield takeLatest(shopTypes.GET_PRODUCTS_REQUEST, getProductsWorker)
}

export function* setFavouriteProductWatcher() {
    yield takeLatest(shopTypes.SET_FAVOURITE_PRODUCT_REQUEST, setFavouriteProductWorker)
}

export function* removeFavouriteProductWatcher() {
    yield takeLatest(shopTypes.REMOVE_FAVOURITE_PRODUCT_REQUEST, removeFavouriteProductWorker)
}