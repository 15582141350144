import { createAction } from '@reduxjs/toolkit'

import { authenticationTypes } from './types'

export const authenticationActions = {
    loginRequest: createAction(authenticationTypes.LOGIN_REQUEST),
    loginSuccess: createAction(authenticationTypes.LOGIN_SUCCESS, data => ({ payload: data })),
    loginFailed: createAction(authenticationTypes.LOGIN_FAILED, data => ({ payload: data })),
    loginAsync: createAction(authenticationTypes.LOGIN_ASYNC, data => ({ payload: data })),

    registrationRequest: createAction(authenticationTypes.REGISTRATION_REQUEST),
    registrationSuccess: createAction(authenticationTypes.REGISTRATION_SUCCESS, data => ({
        payload: data,
    })),
    registrationFailed: createAction(authenticationTypes.REGISTRATION_FAILED, data => ({
        payload: data,
    })),
    registrationAsync: createAction(authenticationTypes.REGISTRATION_ASYNC, data => ({
        payload: data,
    })),

    logoutRequest: createAction(authenticationTypes.LOGOUT_REQUEST),
    logoutSuccess: createAction(authenticationTypes.LOGOUT_SUCCESS),
    logoutFailed: createAction(authenticationTypes.LOGOUT_FAILED, data => ({ payload: data })),
    logoutAsync: createAction(authenticationTypes.LOGOUT_ASYNC),

    checkRequest: createAction(authenticationTypes.REFRESH_REQUEST),
    checkSuccess: createAction(authenticationTypes.REFRESH_SUCCESS, data => ({ payload: data })),
    checkFailed: createAction(authenticationTypes.REFRESH_FAILED, data => ({ payload: data })),
    checkAsync: createAction(authenticationTypes.REFRESH_ASYNC),
}
