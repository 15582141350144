import qs from "qs";
import baseApi from "./baseApi";
import {FavouriteProductsList} from "../models/profileTypes";
import { AxiosResponse } from "axios";

const profileApi = {
    getProfile: () => {
        return baseApi.get('/profile')
    },

    getFavourites: (payload: { pageNumber: number, pageSize: number }): Promise<AxiosResponse<FavouriteProductsList>> => {
        return baseApi.get('/favourites', {
            params: payload, paramsSerializer: {
                serialize: (params) => {
                    return qs.stringify(params)
                },
            }
        })
    },
}

export default profileApi