import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import React, { useEffect } from "react";

type IFilterWrapper = {
    title: string
    type: string
    children: React.ReactNode
}

const FilterWrapper = (props: IFilterWrapper) => {
    const [open, setOpen] = React.useState(true)

    useEffect(() => {
        if(props.type === 'mobile') {
            setOpen(false)
        }
    }, [])

    return (
        <>
            <ListItemButton
                onClick={() => setOpen(!open)}
                sx={{
                    "&.MuiButtonBase-root": { flexDirection: 'row-reverse', paddingLeft: '0', gap: '10px' },
                    "& .MuiTypography-root": { fontWeight: '600', fontFamily: "SF Pro Display, sans-serif", color: "#2F3035", },
                    "& .MuiSvgIcon-root path": { color: '#C4C4C4' }
                }}
                disableRipple
            >
                <ListItemText primary={props.title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{"&.MuiButtonBase-root": { paddingLeft: '7px', paddingRight: '0' }}} disableRipple>
                        {props.children}
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    )
}

export default FilterWrapper