import React, {useEffect, useRef, useState} from "react"
import styles from './Header.module.scss'
import { ReactComponent as LogoIcon } from '../../assets/images/logo.svg'
import { ReactComponent as MobileLogoIcon } from '../../assets/images/logo_mobile.svg'
import { ReactComponent as MenuIcon } from '../../assets/images/icons/menu.svg'
import PersonIcon from '@mui/icons-material/Person';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Link, useNavigate } from "react-router-dom"
import { ReactComponent as LocationIcon } from '../../assets/images/icons/location.svg'
import { ReactComponent as CartIcon } from '../../assets/images/icons/cart.svg'
import {UserIcon} from "../UserIcon";
import classNames from "classnames";
import {LoginRegisterModal} from "../LoginRegisterModal";
import { useSelector } from "react-redux"
import {getAuthentication} from "../../bus/authentication/selectors";
import { FavoriteBorder } from "@mui/icons-material"

const Header = () => {
    const [openLoginModal, setOpenLoginModal] = useState(false)
    const [openRegisterModal, setOpenRegisterModal] = useState(false)
    const [openMobileMenu, setOpenMobileMenu] = useState(false)
    const navigate = useNavigate();
    const isAuth = useSelector(getAuthentication).isAuth
    const ref = useRef(null)

    const handleUserIconClick = () => {
        isAuth ? navigate('/account') : setOpenLoginModal(true)
    }

    useEffect(() => {
        const clickOutsideHandler = (e: any) => {
            // @ts-ignore
            if (ref.current && !ref.current.contains(e.target)) {
                setOpenMobileMenu(false)
            }
        }
        document.addEventListener('click', clickOutsideHandler, false)
        return () => {
            document.removeEventListener('click', clickOutsideHandler, false)
        }
    },[])

    return (
        <div className={styles.header} ref={ref}>
            {openMobileMenu ?
                <div className={styles.menu__mobile}>
                    <div className={styles.mobile__close} onClick={() => setOpenMobileMenu(false)}><CloseOutlinedIcon/></div>
                    <div className={styles.mobile__item} onClick={() => setOpenLoginModal(true)}>
                        <div className={styles.mobile__icon}><PersonIcon/></div> Войти
                    </div>
                    <div className={styles.mobile__item} onClick={() => setOpenRegisterModal(true)}>
                        <div className={styles.mobile__icon}><PersonIcon/></div> Регистрация
                    </div>
                    <Link className={styles.mobile__item} to={'/account'}>
                        <div className={styles.mobile__icon}><FavoriteBorder/></div> Избранное
                    </Link>
                    <div className={styles.mobile__item}>
                        <div className={styles.mobile__icon}><CartIcon/></div> Корзина
                    </div>
                    <div className={styles.mobile__item}>
                        <div className={styles.mobile__icon}><HomeOutlinedIcon/></div> Магазины
                    </div>
                    <div className={styles.mobile__item}>
                        <div className={styles.mobile__icon}><PercentOutlinedIcon/></div> Акции
                    </div>
                    <div className={styles.mobile__item}>
                        <div className={styles.mobile__icon}><LocalShippingOutlinedIcon/></div> Акции
                    </div>
                    <div className={styles.mobile__item}><div className={styles.mobile__icon}></div> Квадрациклы</div>
                    <div className={styles.mobile__item}><div className={styles.mobile__icon}></div> Катера</div>
                    <div className={styles.mobile__item}><div className={styles.mobile__icon}></div> Гидроциклы</div>
                    <div className={styles.mobile__item}><div className={styles.mobile__icon}></div> Лодки</div>
                    <div className={styles.mobile__item}><div className={styles.mobile__icon}></div> Вездеходы</div>
                    <div className={styles.mobile__item}><div className={styles.mobile__icon}></div> Снегоходы</div>
                    <div className={styles.mobile__item}><div className={styles.mobile__icon}></div> Двигатели</div>
                    <div className={styles.mobile__item}><div className={styles.mobile__icon}></div> Запчасти</div>
                </div>
                : ''
            }
            <div className={styles.menu}>
                <div className={styles.menu__nav}>
                    <Link className={styles.menu__item} to={'/shops'}>Магазины</Link>
                    <Link className={styles.menu__item} to={'/sale'}>Акции</Link>
                    <Link className={styles.menu__item} to={'/payment'}>Доставка и оплата</Link>
                </div>
                <div className={styles.menu__mobile_icon} onClick={() => setOpenMobileMenu(!openMobileMenu)}>
                    <MenuIcon/>
                </div>
                <div className={styles.header__logo}>
                    <LogoIcon />
                    <MobileLogoIcon />
                </div>
                <div className={styles.menu__info}>
                    <address className={styles.menu__location}>
                        <LocationIcon/>
                        Москва, ул. Науки 25
                    </address>
                    <div className={styles.menu__icons}>
                        <div onClick={handleUserIconClick}>
                            <UserIcon isAuth={isAuth} />
                        </div>
                        <div className={styles.menu__icons_cart}>
                            <CartIcon/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.header__sections}>
                <div className={styles.header__section}>Квадроциклы</div>
                <div className={styles.header__section}>Катера</div>
                <div className={classNames(styles.header__section, styles.header__section_active)}>Гидроциклы</div>
                <div className={styles.header__section}>Лодки</div>
                <div className={styles.header__section}>Вездеходы</div>
                <div className={styles.header__section}>Снегоходы</div>
                <div className={styles.header__section}>Двигатели</div>
                <div className={styles.header__section}>Запчасти</div>
            </div>
            <LoginRegisterModal
                openLoginModal={openLoginModal}
                openRegisterModal={openRegisterModal}
                setOpenLoginModal={setOpenLoginModal}
                setOpenRegisterModal={setOpenRegisterModal}
            />
        </div>
    )
}

export default Header