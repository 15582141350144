import classNames from "classnames";
import React from "react";
import styles from './Promo.module.scss'

type IPromo = {
    name: string
    // id: string
    inactive: boolean
}

const Promo = (props: IPromo) => {
    const { name, inactive } = props;
    return (
        <div className={classNames(styles.promo, inactive ? styles.promo__inactive : '',
            !inactive && name === 'ДИЛЕР' ? styles.promo__diler : '' )} >
            {name}
        </div>
    )
}

export default Promo