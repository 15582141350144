import React, { Dispatch, SetStateAction, useState } from "react";
import styles from './Search.module.scss'


type ISearch = {
    setValue: Dispatch<SetStateAction<string>>
}

const Search = (props: ISearch) => {
    const [searchValue, setSearchValue] = useState<string>('')
    const { setValue } = props

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }

    const onButtonClick = () => {
        setValue(searchValue);
    }

    return (
        <div className={styles.search}>
            <div className={styles.search__title}>Поиск по названию товара</div>
            <input className={styles.search__input} value={searchValue} placeholder={'Введите марку'} type={"text"} onInput={onInputChange} />
            <button className={styles.search__button} onClick={onButtonClick}>Искать</button>
        </div>
    )
}

export default Search