import axios from 'axios'
import {authenticationActions} from "../bus/authentication/actions";
import store from "../redux/store";


const baseApi = axios.create({
    baseURL: 'https://frontexam.dock8.66bit.ru',
})

baseApi.interceptors.request.use(config => {
    const token = localStorage.getItem('@ACCESS_TOKEN')
    config.headers[`Access-Control-Allow-Credentials`] = true
    config.headers['Authorization'] = `Bearer ${token}`
    config.headers['accept'] = '*/*'
    config.headers['Content-Type'] = 'application/json'
    return config
})

baseApi.interceptors.response.use(
    config => {
        return config
    },
    async error => {
        const originalRequest = error.config

        if (error.response.status !== 401) {
            //if (error.response.config.method !== 'post') {
            //TODO... don't work with error in POST requests (error.response.data is object)
            // notification(null, error.response.data, null)
            //}
            return error.response.data
        }

        if (error.response.status === 401 && !error.config._isRetry) {
            originalRequest._isRetry = true
            try {
                const response: any = await axios({
                    method: 'GET',
                    baseURL: 'https://frontexam.dock8.66bit.ru',
                    url: '/refresh',
                    withCredentials: true,
                })

                localStorage.setItem('@ACCESS_TOKEN', response.data.token)
                return baseApi.request(originalRequest)
            } catch (error) {
                console.log(error)
            }
        }

        if (error.response.status === 401 && error.config?._isRetry) {
            store.dispatch(authenticationActions.logoutAsync())
            localStorage.removeItem('@ACCESS_TOKEN')
        }
    }
)

export default baseApi
