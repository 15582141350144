import { createReducer } from '@reduxjs/toolkit'
import {profileActions} from "./actions";
import {authenticationActions} from "../authentication/actions";
import {FavouriteProductsList} from "../../models/profileTypes";

type InitialState = {
    username: string
    favourites: FavouriteProductsList
}

const initialState: InitialState = {
    username: '',
    favourites: { pageCount: 0, products: [] }
}

export const profileReducer = createReducer(initialState, builder => {
    builder.addCase(profileActions.getProfileRequest, state => ({
        ...state,
    }))
    builder.addCase(profileActions.getProfileSuccess, (state, action) => ({
        ...state,
        username: action.payload.username,
    }))
    builder.addCase(profileActions.getProfileFailed, (state, action) => ({
        ...state,
        error: action.payload,
    }))

    builder.addCase(authenticationActions.logoutSuccess, state => ({
        ...state,
        ...initialState,
    }))

    builder.addCase(profileActions.getProfileFavouritesRequest, state => ({
        ...state,
    }))

    builder.addCase(profileActions.getProfileFavouritesSuccess, (state, action: any) => ({
        ...state,
        favourites: {pageCount: action.payload.pageCount, products: action.payload.products}
    }))

    builder.addCase(profileActions.getProfileFavouriteFailure, state => ({
        ...state,
        ...initialState,
    }))
})
