import {Favorite, FavoriteBorder } from '@mui/icons-material'
import Checkbox from '@mui/material/Checkbox'
import React, { useState } from 'react'
import styles from './Product.module.scss'
import { ReactComponent as Cart } from '../../assets/images/icons/cart.svg'
import {ShopProduct} from "../../models/shopTypes";
import {Promo} from "../../ui/Promo";
import { useDispatch, useSelector } from 'react-redux'
import {shopActions} from "../../bus/shop/actions";
import {getAuthentication} from "../../bus/authentication/selectors";

const Product = (props: ShopProduct) => {
    const {
        title,
        img,
        isInInventory,
        isOnRequest,
        price,
        modelId,
        brandId,
        countryId,
        actions,
        isFavourite,
        id
    } = props, promoTypes: { [key: string]: string } = {'0': 'SALE', '1': 'NEW', '2': 'HIT', '3': 'ДИЛЕР'};

    const dispatch = useDispatch()
    const isAuth = useSelector(getAuthentication).isAuth

    const [checked, setChecked] = useState<boolean>(isFavourite)

    const setRemoveFavourite = () => {
        if(isAuth) {
            if(checked) {
                dispatch(shopActions.removeFavouriteProductRequest(id))
                setChecked(false)
            } else {
                dispatch(shopActions.setFavouriteProductRequest(id))
                setChecked(true)
            }
        }
    }

    return (
        <div className={styles.product} id={id}>
            <div className={styles.product__favourite}>
                <Checkbox
                    icon={<FavoriteBorder />}
                    checked={checked}
                    checkedIcon={<Favorite />}
                    sx={{'&.Mui-checked' : {color: '#1C62CD'}}}
                    onChange={setRemoveFavourite}
                />
            </div>
            <div className={styles.product__promo}>
                {actions[0] !== undefined ? <Promo name={promoTypes[String(actions[0])]} inactive={false}/> : ''}
            </div>
            <div className={styles.product__img}>
                <img src={img} alt={title} />
            </div>
            <div className={styles.product__title}>{title}</div>
            {isInInventory ? <div className={styles.product__price}>{price} ₽</div> : ''}
            {isOnRequest ?
                <div className={styles.product__request}>
                    <div className={styles.product__request_text}>нет в наличии</div>
                    <div className={styles.product__request_text}>Сообщить о поступлении</div>
                </div>
                : ''}
            {isOnRequest ? '' : <div className={styles.product__cart}><Cart/></div>}
        </div>
    )
}

export default Product