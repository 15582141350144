import React, { useEffect } from 'react';
import { setRoutes } from "./index";
import {getAuthentication} from "../bus/authentication/selectors";
import {useDispatch, useSelector } from 'react-redux';
import {authenticationActions} from "../bus/authentication/actions";
import {profileActions} from "../bus/profile/actions";

function App() {
  const { isAuth } = useSelector(getAuthentication)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(authenticationActions.checkAsync())
  }, [])

  useEffect(() => {
    isAuth && dispatch(profileActions.getProfileAsync())
  }, [isAuth])

  return setRoutes(isAuth);
}

export default App;
