export const shopTypes = {
    GET_FILTERS_REQUEST: `GET_SHOP_FILTERS_REQUEST`,
    GET_FILTERS_SUCCESS: `GET_SHOP_FILTERS_SUCCESS`,
    GET_FILTERS_FAILURE: `GET_SHOP_FILTERS_FAILURE`,

    GET_PRODUCTS_REQUEST: 'GET_SHOP_PRODUCTS_REQUEST',
    GET_PRODUCTS_SUCCESS: 'GET_SHOP_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAILURE: 'GET_SHOP_PRODUCTS_FAILURE',

    SET_FAVOURITE_PRODUCT_REQUEST: 'SET_FAVOURITE_PRODUCT_REQUEST',
    SET_FAVOURITE_PRODUCT_SUCCESS: 'SET_FAVOURITE_PRODUCT_SUCCESS',
    SET_FAVOURITE_PRODUCT_FAILURE: 'SET_FAVOURITE_PRODUCT_FAILURE',

    REMOVE_FAVOURITE_PRODUCT_REQUEST: 'REMOVE_FAVOURITE_PRODUCT_REQUEST',
    REMOVE_FAVOURITE_PRODUCT_SUCCESS: 'REMOVE_FAVOURITE_PRODUCT_SUCCESS',
    REMOVE_FAVOURITE_PRODUCT_FAILURE: 'REMOVE_FAVOURITE_PRODUCT_FAILURE',

    CLEAR_STATE: 'CLEAR_SHOP_FILTERS_STATE',
}
