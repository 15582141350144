import { combineReducers } from 'redux'
import {shopReducer} from "../../bus/shop/reducer";
import {authenticationReducer} from "../../bus/authentication/reducer";
import {profileReducer} from "../../bus/profile/reducer";

export const rootReducer = () =>
    combineReducers({
        authentication: authenticationReducer,
        shop: shopReducer,
        profile: profileReducer,
    })
