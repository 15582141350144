import React from "react";
import styles from './Breadcrumbs.module.scss'
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/images/icons/arrow-right.svg";

const Breadcrumbs = () => {
    const pathNames = [
        { url: '/shop', names: ['Главная', 'Гидроциклы'] },
        { url: '/account', names: ['Главная', 'Личный кабинет']}
    ]

    const paths: Object = {
        'Главная': '/',
        'Гидроциклы': '/shop',
        'Личный кабинет': '/account'
    }

    const currentPath = window.location.pathname

    return (
        <div className={styles.breadcrumbs}>
            {pathNames.map((x: { names: string[], url: string }) => {
                if(currentPath === x.url) {
                    return x.names.map((name: string, index : number) => {
                        return x.names.length === index + 1
                            ?
                            <div key={index}>{name}</div>
                            :
                            <div className={styles.breadcrumbs__path} key={index}>
                                <Link key={index} to={String(paths[name as keyof Object])}>
                                    {name}
                                </Link>
                                <ArrowRight />
                            </div>
                    })
                }
            })}
        </div>
    )
}

export default Breadcrumbs