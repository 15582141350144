export const profileTypes = {
    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILED: 'GET_PROFILE_FAILED',
    GET_PROFILE_ASYNC: 'GET_PROFILE_ASYNC',

    GET_PROFILE_FAVOURITES_REQUEST: 'GET_PROFILE_FAVOURITES_REQUEST',
    GET_PROFILE_FAVOURITES_SUCCESS: 'GET_PROFILE_FAVOURITES_SUCCESS',
    GET_PROFILE_FAVOURITES_FAILURE: 'GET_PROFILE_FAVOURITES_FAILURE',
    // GET_PROFILE_FAVOURITES_ASYNC: 'GET_PROFILE_FAVOURITES_ASYNC'
}
