import Modal from '@mui/material/Modal'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ReactComponent as LogoIcon } from '../../assets/images/logo.svg'
import styles from './LoginRegisterModal.module.scss'
import {LoginData, RegisterData} from "../../models/authenticationTypes";
import {authenticationActions} from "../../bus/authentication/actions";
import { useDispatch, useSelector } from 'react-redux';
import {getAuthentication} from "../../bus/authentication/selectors";

type LoginRegisterModalType = {
    openLoginModal: boolean,
    setOpenLoginModal: Dispatch<SetStateAction<boolean>>
    openRegisterModal: boolean,
    setOpenRegisterModal: Dispatch<SetStateAction<boolean>>
}

export const LoginRegisterModal = (props: LoginRegisterModalType) => {
    const dispatch = useDispatch()
    const { openLoginModal, setOpenLoginModal, openRegisterModal, setOpenRegisterModal } = props
    const [loginForm, setLoginForm] = useState<LoginData>({email: '', password: ''})
    const [registerForm, setRegisterForm] = useState<RegisterData>({name: '', email: '', password: ''})
    const [copyError, setCopyError] = useState<string | null>(null);
    const [resultModal, setResultModal] = useState<boolean>(false);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(openLoginModal) setLoginForm({ ...loginForm, [event.target.name]: event.target.value })
        else setRegisterForm({ ...registerForm, [event.target.name]: event.target.value})
    };

    const loginHandler = () => {
        dispatch(authenticationActions.loginAsync(loginForm))
    }

    const registrationHandler = () => {
        dispatch(authenticationActions.registrationAsync(registerForm))
    }

    const { error } = useSelector(getAuthentication)


    useEffect(() => {
        setCopyError(error)
        if(!(!!error) && openRegisterModal) setResultModal(true)
    }, [error])

    return (
        <>
            <Modal
                open={openLoginModal}
                onClose={() => setOpenLoginModal(false)}
            >
                <div className={styles.modal}>
                    <div className={styles.modal__logo}>
                        <LogoIcon/>
                    </div>
                    <div className={styles.modal__title}>Войти</div>
                    <form className={styles.modal__form}>
                        <input
                            className={styles.modal__input}
                            placeholder={'Email'}
                            onChange={changeHandler}
                            name={'email'}
                            type={'email'}
                        />

                        <input
                            className={styles.modal__input}
                            placeholder={'Пароль'}
                            onChange={changeHandler}
                            name={'password'}
                            type={'password'}
                        />
                    </form>
                    <button className={styles.modal__submit} onClick={loginHandler}>Войти</button>
                    <div className={styles.modal__redirect} onClick={()=>{
                        setOpenLoginModal(false) ; setOpenRegisterModal(true) ; setCopyError(null)
                    }}>Регистрация</div>
                    {copyError !== null ? <div className={styles.modal__error}>{copyError}</div> : ''}
                </div>
            </Modal>
            <Modal
                open={openRegisterModal}
                onClose={() => setOpenRegisterModal(false)}
            >
                <div className={styles.modal}>
                    <div className={styles.modal__logo}>
                        <LogoIcon/>
                    </div>
                    <div className={styles.modal__title}>Регистрация</div>
                    <form className={styles.modal__form}>
                        <input
                            className={styles.modal__input}
                            placeholder={'ФИО'}
                            onChange={changeHandler}
                            name={'name'}
                            type={'text'}
                        />

                        <input
                            className={styles.modal__input}
                            placeholder={'Email'}
                            onChange={changeHandler}
                            name={'email'}
                            type={'email'}
                        />

                        <input
                            className={styles.modal__input}
                            placeholder={'Пароль'}
                            onChange={changeHandler}
                            name={'password'}
                            type={'password'}
                        />
                    </form>
                    <button className={styles.modal__submit}
                            onClick={registrationHandler}
                    >Регистрация</button>
                    <div className={styles.modal__redirect} onClick={()=>{
                        setOpenRegisterModal(false); setOpenLoginModal(true); setCopyError(null)
                    }}>Вход</div>
                    {copyError !== null ? <div className={styles.modal__error}>{copyError}</div> : ''}
                </div>
            </Modal>
            <Modal
                open={resultModal}
                onClose={() => setResultModal(false)}
            >
                <div className={styles.modal}>
                    <div className={styles.modal__result}>
                        Регистрация прошла успешно
                    </div>
                    <a href={'/account'} className={styles.modal__submit} style={{textDecoration: 'none'}}>В личный кабинет</a>
                </div>
            </Modal>
        </>

    )
}

export default LoginRegisterModal