import React, {useState, useEffect} from "react"
import styles from './ShopPage.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"
import BannerSlide from "../../assets/images/shop/banner-slide.jpg"
import PromoPicture from '../../assets/images/shop/promo-pic.jpg'
import './swiper.scss'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Search } from "../../components/Search";
import { ShopFilters } from "../../components/ShopFilters";
import { shopActions } from "../../bus/shop/actions";
import {Product} from "../../components/Product";
import {getShopProducts} from "../../bus/shop/selectors";
import {ShopProduct} from "../../models/shopTypes";
import PaginationMui from '@mui/material/Pagination';
import {FilterWrapper} from "../../components/FilterWrapper";


const ShopPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(shopActions.getFiltersRequest())
        dispatch(shopActions.getProductsRequest({ 'PageNumber': 1}))
    },[])

    const shopInfo = useSelector(getShopProducts)

    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
        dispatch(shopActions.getProductsRequest({ 'PageNumber': value }))
    }

    return (
        <div className={styles.shop}>
            <div className={styles.shop__banners}>
                <Swiper
                    modules={[Navigation, Pagination]}
                    navigation
                    pagination={{ clickable: true }}
                    slidesPerView={1}
                    className={styles.shop__swiper}
                >
                    <SwiperSlide><img src={BannerSlide} alt='' /></SwiperSlide>
                    <SwiperSlide><img src={BannerSlide} alt='' /></SwiperSlide>
                    <SwiperSlide><img src={BannerSlide} alt='' /></SwiperSlide>
                    <SwiperSlide><img src={BannerSlide} alt='' /></SwiperSlide>
                    <SwiperSlide><img src={BannerSlide} alt='' /></SwiperSlide>
                    <SwiperSlide><img src={BannerSlide} alt='' /></SwiperSlide>
                </Swiper>
                <div className={styles.shop__promo}>
                    <div className={styles.promo__top}>
                        <div className={styles.promo__label}>Акция</div>
                        <div className={styles.promo__price}>
                            <div className={styles.promo__price__new}>190 000 ₽</div>
                            <div className={styles.promo__price__old}>225 000 ₽</div>
                        </div>
                    </div>
                    <div className={styles.promo__pic}>
                        <img src={PromoPicture} alt='' />
                    </div>
                    <div className={styles.promo__title}>
                        Лодочный мотор Suzuki DF9.9BRS
                    </div>
                    <div className={styles.promo__date}>
                        Акция действует до <span>31.08.2020</span>
                    </div>
                </div>
            </div>
            <Search setValue={setSearch} />
            <h1>Гидроциклы</h1>
            <div className={styles.shop__products}>
                <div className={styles.shop__filters_mobile}>
                    <FilterWrapper title={'Фильтр'} type={'mobile'}>
                        <ShopFilters/>
                    </FilterWrapper>
                </div>
                <div className={styles.shop__filters}>
                    <ShopFilters />
                </div>
                <div>
                    <div className={styles.shop__products_list}>
                        {shopInfo.products
                            .filter(item => new RegExp(search, 'i').test(item.title))
                            .map((x: ShopProduct, index: number) => {
                            return <Product
                                key={index}
                                title={x.title}
                                img={x.img}
                                isInInventory={x.isInInventory}
                                isOnRequest={x.isOnRequest}
                                price={x.price}
                                modelId={x.modelId}
                                brandId={x.brandId}
                                countryId={x.countryId}
                                actions={x.actions}
                                isFavourite={x.isFavourite}
                                id={x.id}
                            />
                        })}
                    </div>
                    <PaginationMui
                        count={shopInfo.pageSize}
                        page={page}
                        sx={{
                            "& .MuiPagination-ul": { gap: '8px', justifyContent: 'center' },
                            "& .MuiPaginationItem-root": { width: '39px', height: '39px',  fontFamily: 'Barlow, sans-serif', fontSize: '18px', fontWeight: '600',
                                borderRadius: '0', backgroundColor: '#FFFFFF'},
                            "& .Mui-selected": {border: '2px solid #1C62CD', color: '#1C62CD'}
                        }}
                        onChange={handleChangePage}
                        hideNextButton
                        hidePrevButton
                    />
                </div>
            </div>
        </div>
    )
}

export default ShopPage