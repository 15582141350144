import React from "react";
import styles from './UserIcon.module.scss'
import user from '../../assets/images/icons/user.svg'
import {getProfile} from "../../bus/profile/selectors";
import { useSelector } from "react-redux";

type IUserIcon = {
    isAuth: any
}

const UserIcon = (props: IUserIcon) => {
    const { isAuth } = props
    const username = useSelector(getProfile).username

    return (
        <div className={styles.user}>
            <img src={user} className={styles.user__logo} alt={'Пользователь'} />
            {isAuth ?
                <div className={styles.user__text}>
                    {username}
                </div>
                :
                <div className={styles.user__text}>
                    Войти
                </div>
            }
        </div>
    )
}

export default UserIcon