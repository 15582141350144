import { createReducer } from '@reduxjs/toolkit'

import { authenticationActions } from './actions'

interface InitialState {
    isAuth: boolean
    registrationEmail: string | null
    isLoadingLogin: boolean
    isLoadingRegistration: boolean
    isLoadingLogout: boolean
    isLoadingCheck: boolean
    isLoadingPasswordRecovery: boolean
    error: string | null
}

const initialState: InitialState = {
    isAuth: false,
    registrationEmail: '',
    isLoadingLogin: false,
    isLoadingRegistration: false,
    isLoadingLogout: false,
    isLoadingCheck: false,
    isLoadingPasswordRecovery: false,
    error: null,
}

export const authenticationReducer = createReducer(initialState, builder => {
    builder.addCase(authenticationActions.loginRequest, state => ({
        ...state,
        isLoadingLogin: true,
        error: null,
    }))
    builder.addCase(authenticationActions.loginSuccess, (state, action) => ({
        ...state,
        isAuth: action.payload,
        isLoadingLogin: false,
    }))
    builder.addCase(authenticationActions.loginFailed, (state, action) => ({
        ...state,
        isLoadingLogin: false,
        error: action.payload,
    }))

    builder.addCase(authenticationActions.registrationRequest, state => ({
        ...state,
        isLoadingRegistration: true,
        error: null,
    }))
    builder.addCase(authenticationActions.registrationSuccess, (state, action) => ({
        ...state,
        isAuth: action.payload,
        isLoadingRegistration: false,
    }))
    builder.addCase(authenticationActions.registrationFailed, (state, action) => ({
        ...state,
        isLoadingRegistration: false,
        error: action.payload,
    }))

    builder.addCase(authenticationActions.checkRequest, state => ({
        ...state,
        isLoadingCheck: true,
        error: null,
    }))
    builder.addCase(authenticationActions.checkSuccess, (state, action) => ({
        ...state,
        isAuth: action.payload,
        isLoadingCheck: false,
    }))
    builder.addCase(authenticationActions.checkFailed, (state, action) => ({
        ...state,
        isLoadingCheck: false,
        error: action.payload,
    }))

    builder.addCase(authenticationActions.logoutRequest, state => ({
        ...state,
        isLoadingLogout: true,
        error: null,
    }))
    builder.addCase(authenticationActions.logoutSuccess, (state, action) => ({
        ...state,
        ...initialState,
    }))
    builder.addCase(authenticationActions.logoutFailed, (state, action) => ({
        ...state,
        isLoadingLogout: false,
        error: action.payload,
    }))
})
